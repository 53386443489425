import { Component, Vue, Watch } from 'vue-property-decorator'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import { mapState } from 'vuex'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrAdminUserGroupView',
  computed: {
    ...mapState('company', ['user_group', 'user_group_members', 'permissions'])
  }
})
export default class GtrAdminUserGroupView extends Vue {
  user_group!: Record<string, any>

  membersKeys = [
    { text: 'Name', value: 'name' },
    { text: 'Email', value: 'email' },
    // TODO: change to `last_active` once added to user table.
    { text: 'Last Active', value: 'updated_at' }
  ]

  data () {
    return {
      submitting: false,
      invalid: false,
      currentTab: null,
      groupMembersFiltered: [],
      groupPermissions: {},
      uuid: this.$route.params.uuid,
      loading: false
    }
  }

  @Watch('user_group')
  onUserGroupChange (payload: any) {
    if (payload) {
      this.$data.groupPermissions = payload.permissions
    }
  }

  @Watch('user_group_members')
  onUserGroupMembersChange (payload: any) {
    const result: any[] = []
    for (const i in payload) {
      const member = payload[i]
      if (member.active) {
        result.push(member)
      }
    }
    this.$data.groupMembersFiltered = result
    return result
  }

  get userGroupName () {
    if (this.user_group && this.user_group.name) {
      return this.user_group.name
    }
    return ''
  }

  formatDate (date: string): string {
    if (this && this.$options && this.$options.filters) {
      return this.$options.filters.formatDateAndTime(date, null, 'MM/DD/YYYY hh:mm A')
    }
    return date
  }

  /**
   * Disables all permissions of a category if its view permission is false.
   * @param categoryViewPermission A permission string that controls view permission. Of the form ${tab}.${category}.view
   */
  categoryAccessChangeHandler (categoryViewPermission: string) {
    const category = categoryViewPermission.substring(0, categoryViewPermission.lastIndexOf('.'))
    const groupPermissions = this.$data.groupPermissions

    if (!groupPermissions[categoryViewPermission]) {
      for (const key in groupPermissions) {
        if (category === key.substring(0, key.lastIndexOf('.'))) {
          if (typeof groupPermissions[key] === 'boolean') {
            groupPermissions[key] = false
          }
        }
      }
    }
  }

  /**
   * Sets all permissions on the tab to false except the tab access permission if the access permission is false.
   * @param tabAccessPermission A permission string that controls access to a whole tab. Of the form ${tab}.access.view
   */
  tabAccessChangeHandler (tabAccessPermission: string) {
    const tab = tabAccessPermission.substring(0, tabAccessPermission.indexOf('.'))
    const groupPermissions = this.$data.groupPermissions

    if (!groupPermissions[tabAccessPermission]) {
      for (const key in groupPermissions) {
        if (key.substring(0, key.indexOf('.')) === tab && key !== tabAccessPermission) {
          if (typeof groupPermissions[key] === 'boolean') {
            groupPermissions[key] = false
          }
        }
      }
    }
  }

  /**
   * Calls the appropriate function based on whether the triggering element sets view permissions for a category or a tab.
   * @param viewPermission A permission string that controls view permission. Of the form ${tab}.${category}.view
   */
  categorySwitchHandler (viewPermission: string) {
    if (viewPermission.indexOf('.access.view') > 0) {
      this.tabAccessChangeHandler(viewPermission)
    } else {
      this.categoryAccessChangeHandler(viewPermission)
    }
  }

  /**
   * Given a permission string, determines whether the tab it's on is enabled or disabled.
   * @param permission Of the form ${tab}.${category}.${permission}
   * @returns true if tab access is enabled; false if not
   */
  tabAccessEnabled (permission: string): boolean {
    const tab = permission.substring(0, permission.indexOf('.'))
    const groupPermissions = this.$data.groupPermissions
    let tabAccessPermission = true

    for (const key in groupPermissions) {
      if (key === tab + '.access.view') {
        tabAccessPermission = groupPermissions[key]
        break
      }
    }

    return tabAccessPermission
  }

  async submit () {
    try {
      this.$data.submitting = true
      await this.$store.dispatch('company/saveUserGroupPermissions', { uuid: this.$route.params.uuid, permissions: this.$data.groupPermissions })
      Container.get(Notification).success('Permissions Updated')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.submitting = false
    }
  }

  async mounted () {
    await this.fetchUserGroup()
  }

  handleViewGroup (user_group: any) {
    this.$store.commit('company/SET_USER_GROUP', user_group)
    this.$router.push({ name: 'level-one.user_groups.edit', params: { uuid: user_group.uuid } })
  }

  private async fetchUserGroup () {
    try {
      this.$data.loading = true
      // this.$store.commit('company/SET_USER_GROUP', [])
      await this.$store.dispatch('company/fetchUserGroup', this.$route.params.uuid)
      await this.$store.dispatch('company/fetchUserGroupMembers', this.$route.params.uuid)
      await this.$store.dispatch('company/fetchPermissions')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
